import * as React from 'react';
import { HashRouter as Router, Route, NavLink, Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { EthContext } from 'eth-common/EthContext';
import Moment from 'react-moment';
import i18n from 'eth-common/i18n/i18n';
import fetch from "isomorphic-fetch";
import DMInit from 'digimaker-ui/DMInit';
import {FetchWithAuth} from 'digimaker-ui/util';


export default class ImportReport extends React.Component<{}, {shown: boolean, data: any, success:any}> {

  constructor(props){
    super(props);
    this.state = {shown: false, data:null, success: null};
  }

  componentDidMount(){
    this.fetch();
  }


  fetch(){
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/getwithlevel?year='+(this.context.currentYear-1))
        .then((data) => {
          if( data ){
              this.setState({data: data});
          }else{
            this.setState({data: ''});
          }
        })
  }


  //convert last year to new year
  convertIndicator(indicatorData){
      for( let i in indicatorData ){
        let item = indicatorData[i];
        if( item.type == "-1" ){
          indicatorData[i]['type'] = "-2";
        }
        if( item.type == "-2" ){
          indicatorData[i]['type'] = "-3";
        }
        if( item.type == "-3" ){
          indicatorData[i]['type'] = "-4";
        }
      }
      return indicatorData;
  }

  import(){
     let data = this.state.data;
     if( data ){
       //send to server.

       FetchWithAuth(process.env.REACT_APP_REMOTE_URL+'/contenttype/get/report?language=eng-GB').then(defData=>{
          let def = defData.data;
          let url = '/content/savedraft/' + process.env.REACT_APP_FORM_PARENT_ID + '/report';

          let fields = def.fields;

          let fieldsData = {};
          for( let level1 of fields ){
            if( level1.children ){
            for( let level2 of level1.children ){
               if( data[level2.identifier] != undefined ){
                 fieldsData[level2.identifier] = data[level2.identifier];
               }
               if(level2.children){
                 for( let level3 of level2.children ){
                 if( data[level3.identifier] != undefined ){
                   fieldsData[level3.identifier] = data[level3.identifier];
                 }

                 if(level3.children){
                   for( let level4 of level3.children ){
                   if( data[level4.identifier] != undefined ){
                     fieldsData[level4.identifier] = data[level4.identifier];
                   }
                 }
               }
               }
              }
            }
          }
        }

        fieldsData["target_this_year"] = fieldsData["target_next_year"];
        fieldsData["target_next_year"] = {};
        fieldsData["indicator"] = this.convertIndicator( fieldsData["indicator"] );

          let draftData = { "data": JSON.stringify(fieldsData) };
          FetchWithAuth(process.env.REACT_APP_REMOTE_URL + url, { method: 'POST', body: JSON.stringify(draftData) }).then((data:any)=>{
              if( data.error===false ){
                    this.setState({success:true});
              }else{
                throw Error(i18n.t("Server error."));
              }
            }).catch( (ex:any)=>{
              //let savedResult = this.state.savedResult;
              let savedResult = {success: false};
            } );
       });

     }else{
       this.setState({success: false});
     }
  }

  render() {
    return <div className="block">
            {!this.state.shown&&
              <div>
                <button className="btn btn-primary btn-sm" onClick={()=>this.setState({shown: true})}>{i18n.t("Import data from last year")}</button>
              </div>
            }
            {this.state.shown&&<div>
              {this.state.data!==''&&
                <div className="box">
                <div className="block">{i18n.t('You have report from')} {this.context.currentYear-1}. {i18n.t('Click button below to import')}</div>
                <br />
                <div className="alert alert-danger">{i18n.t("Warning")}: {i18n.t("if you import to draft, your existing draft will be overwritten.")}</div>
                <button className="btn btn-primary btn-sm" onClick={()=>this.import()}>{i18n.t('Import to draft')}</button>
                <button className="btn btn-sm" onClick={()=>this.setState({shown: false})}>{i18n.t("Cancel")}</button>
                {this.state.success!==null&&<div>
                    {this.state.success&&<div>{i18n.t('Report is imported to your draft.')} <a href="/"><u>{i18n.t('Refresh page')}</u></a></div>}
                    {!this.state.success&&<div>{i18n.t('Failed, please try again.')}</div>}
                  </div>}
              </div>}
              {this.state.data===''&&<div>{i18n.t("You don't have report from")} {this.context.currentYear-1}
               <button className="btn btn-sm" onClick={()=>this.setState({shown: false})}>{i18n.t("Cancel")}</button></div>}
            </div>}
            </div>
  }
}

ImportReport.contextType = EthContext;
