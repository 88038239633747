import * as React from "react";
import { HashRouter as Router, Route, NavLink, Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { EthContext } from "eth-common/EthContext";
import Moment from "react-moment";
import i18n from "eth-common/i18n/i18n";
import fetch from "isomorphic-fetch";
import DMInit from "digimaker-ui/DMInit";
import { FetchWithAuth } from "digimaker-ui/util";
import { Alert, Container, Nav, Navbar } from "react-bootstrap";
import { Util } from "./util/Util";

export default class Header extends React.Component<
  { updateContext: any },
  { current: any; menu: any; setting: any }
> {
  constructor(props) {
    super(props);
    const defaultLanguage = "nor-NO";
    this.state = {
      current: "",
      menu: [],
      setting: { language: defaultLanguage },
    };
  }

  componentDidUpdate(prevProps, preState) {
    if (preState.setting.language != this.state.setting.language) {
      this.fetchMenu();
    }
  }

  componentDidMount() {
    this.fetchCurrent();
    this.fetchMenu();
  }

  fetchCurrent() {
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/eth/user/current")
      .then((data) => {
        let content = data.data;
        if (content.preferance) {
          let language = content.preferance["language"];
          if (language) {
            i18n.changeLanguage(language);
            this.setState({ setting: { language: language } });
            this.props.updateContext("language", language);
          }
        }
        this.props.updateContext("user", content);
        this.setState({ current: content });
      })
      .catch((err) => {
        this.setState(() => {
          throw err;
        });
      });

    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/eth/report/current-year")
      .then((data) => {
        this.props.updateContext("currentYear", parseInt(data.data));
      })
      .catch(() => {});
  }

  async fetchMenu() {
    let data = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/content/list/folder?parent=" +
        process.env.REACT_APP_ROOT_ID +
        "&field.language=" +
        this.state.setting.language +
        "&level=1&sortby=priority desc"
    );
    if (data.error === false) {
      this.setState({ menu: data.data.list.filter((item) => item.id !== 694) });
    }
  }

  saveLanguage(language: string) {
    let dataObject = { language: language };
    if (this.state.current) {
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/user/savePreferance", {
        method: "POST",
        body: JSON.stringify(dataObject),
      }).then((data) => {
        if (data.error === false) {
          i18n.changeLanguage(language);
          this.setState({ setting: { language: language } });
          this.props.updateContext({ language: language });
        } else {
          i18n.changeLanguage(language);
          this.setState({ setting: { language: language } });
        }
      });
    }
  }

  switchLanguage(e: any, language: string) {
    e.preventDefault();
    this.saveLanguage(language);
  }

  render() {
    let canReport =
      this.state.current &&
      this.state.current.member &&
      (this.state.current.member.can_report ? true : false);

    return (
      <div className="top">
        <div className="language">
          <a href="http://etiskhandel.no" style={{ fontSize: "90%" }}>
            etiskhandel.no
          </a>{" "}
          &nbsp;&nbsp;&nbsp;
          <a
            href="#"
            onClick={(e) => {
              this.switchLanguage(e, "eng-GB");
            }}
            className={
              this.state.setting.language == "eng-GB" ? "current-language" : ""
            }
            title="English"
          >
            EN
          </a>
          &nbsp; | &nbsp;
          <a
            href="#"
            onClick={(e) => {
              this.switchLanguage(e, "nor-NO");
            }}
            className={
              this.state.setting.language == "nor-NO" ? "current-language" : ""
            }
          >
            NO
          </a>
        </div>
        <div className="header">
          <div className="logo">
            <Link to="/">
              <img src={process.env.PUBLIC_URL + "/logo-text.png"} />
            </Link>
          </div>
          <div>
            <h2 id="company_name">
              {this.state.current.company
                ? this.state.current.company.name
                : ""}
            </h2>
          </div>
        </div>
        <div className="menu">
          <div className="usermenu">
            <span>
              <Link to="/profile/view" title="My profile">
                <i className="fas fa-user"></i>
                <span id="current_user">
                  {this.state.current.firstname} {this.state.current.lastname}
                </span>
              </Link>
            </span>
            <span>
              <Link to="/messages" title="My profile">
                <i className="fas fa-inbox"></i>
                <span>{i18n.t("Messages")}</span>
              </Link>
            </span>
            <span>
              <Link to="/logout" title="Logout">
                <i className="fas fa-sign-out-alt"></i>
                {i18n.t("Logout")}
              </Link>
            </span>
          </div>

          <Navbar expand="lg" bg="eth">
            <Container>
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav">
                <Nav className="me-auto">
                  <NavLink activeClassName="selected" to="/dashboard">
                    {i18n.t("Dashboard")}
                  </NavLink>
                  {this.state.menu.map((item, index) => (
                    <NavLink
                      activeClassName="selected"
                      to={Util.getContentUrl(item)}
                    >
                      {item.metadata.name}
                    </NavLink>
                  ))}
                  {canReport && (
                    <NavLink activeClassName="selected" to="/myreport">
                      {i18n.t("Reporting")}
                    </NavLink>
                  )}
                  {this.state.setting.language === "eng-GB" && (
                    <div>
                      <div className="header-message">
                        Note: see the NO member sites for full access to
                        templates and resources.
                      </div>
                    </div>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    );
  }
}

Header.contextType = EthContext;
