import * as React from "react";
import { RouteProps } from "react-router";
import Config from "../config.json";
import EthIndicator from "eth-common/EthIndicator";
import Moment from "react-moment";
import i18n from "eth-common/i18n/i18n";
import { FetchWithAuth } from "digimaker-ui/util";

import { buildObject } from "eth-common/buildObject";
import { RenderFields } from "digimaker-ui";

import { Util } from "../util/Util";
import { Tooltip } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

export default class Step extends React.Component<
  {
    step: number;
    language: string;
    form: any;
    formType: string;
    totalSteps: number;
    onJumpTo: any;
    definition: any;
    onSubmit: any;
    handleAction: any;
    data: any;
    beforeField: any;
  },
  { definition: any; components: {}; validation: any; settingsData: any }
> {
  // private form: any;
  constructor(props: any) {
    super(props);
    this.state = {
      definition: "",
      components: {},
      validation: "",
      settingsData: "",
    };
    // this.form = React.createRef();
  }

  async componentDidMount() {
    if (!this.state.settingsData) {
      let data = await Util.fetchSettings();
      this.setState({ settingsData: data });
    }
  }

  handlNextButton(e: any) {
    this.validate(document.forms[0], (dataObject: any) => {
      this.props.onJumpTo(this.props.step + 1);
    });
  }

  handlePreviousButton(e: any) {
    this.validate(document.forms[0], (dataObject: any) => {
      if (this.props.step > 0) {
        this.props.onJumpTo(this.props.step - 1);
      }
    });
  }

  submit(e: any) {
    this.validate(document.forms[0], (dataObject: any) => {
      if (this.props.step > 0) {
        this.props.onSubmit(dataObject);
      }
    });
  }

  saveDraft() {
    this.props.handleAction("saveDraft");
  }

  validate(form: any, afterSuccess: any) {
    let dataObject = buildObject(form);

    //document list category
    let documentListErrorFields = {};
    for (let key of Object.keys(dataObject)) {
      if (key.startsWith("documentlist")) {
        for (let item of dataObject[key]) {
          if (item["file"] && item["category"] == "") {
            documentListErrorFields[key] = "Need category";
          }
        }
      }
    }
    if (Object.keys(documentListErrorFields).length > 0) {
      this.setState({
        validation: {
          message: "Document error",
          fields: documentListErrorFields,
        },
      });
      return;
    }

    //merge indicator data
    let merged: Array<any> = [];
    let currentData = dataObject["indicator"];
    if (currentData) {
      Object.keys(currentData).map((fromField: any) => {
        currentData[fromField].map((values: any) => {
          merged.push(values);
        });
      });
    }
    dataObject["indicator"] = merged;

    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/form/validate/" +
        this.props.formType +
        "," +
        this.props.definition.identifier,
      {
        method: "POST",
        body: JSON.stringify(dataObject),
      }
    ).then((data) => {
      if (data.data === true) {
        if (afterSuccess) {
          // dataObject = this.mergeIndicator(dataObject);
          afterSuccess(dataObject);
        }
      } else {
        this.setState({ validation: data.data });
      }
    });
  }

  afterField(fieldDef: any, data: any, validation: any) {
    return (
      <>
        {fieldDef.parameters && fieldDef.parameters.has_indicator && (
          <EthIndicator
            fromField={fieldDef.identifier}
            data={data["indicator"]}
            parameters={fieldDef.parameters}
            validation={
              validation && validation.fields["indicator"]
                ? validation.fields["indicator"]
                : ""
            }
          />
        )}
      </>
    );
  }

  getCategory(category, id) {
    let result = category.find((item) => item.id == id);
    if (result) {
      if (this.props.language == "eng-GB") {
        return result["_eng"];
      } else {
        return result.text;
      }
    } else {
      return null;
    }
  }

  renderDocumentList() {
    if (!this.state.settingsData) {
      return <div></div>;
    }
    let data: any = this.props.data;
    let category = this.state.settingsData.documentCategory;
    return (
      <div className="document-list-display">
        <h3>{i18n.t("Document list")}</h3>
        <table>
          <tbody>
            {Object.keys(data).map((key) => {
              if (key.startsWith("document")) {
                //todo: use fieldtype from definition
                let list: any = data[key];
                if (!list) {
                  return "";
                }
                if (typeof list === "string") {
                  console.log(list);
                  list = JSON.parse(list);
                }
                return list.map((item: any) => {
                  if (!item || !item.file) {
                    return "";
                  }
                  return (
                    <tr>
                      <td>
                        <a
                          href={"/var/" + item.file}
                          target="_blank"
                          className="document-title"
                          title={item.name}
                        >
                          {item.category && (
                            <span>
                              [{this.getCategory(category, item.category)}]
                            </span>
                          )}
                          {item.name}
                        </a>
                      </td>
                    </tr>
                  );
                });
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className={"form-step " + this.props.definition.identifier}>
        {this.renderDocumentList()}
        {this.state.validation && (
          <div className="form-messgae alert alert-warning">
            {i18n.t("Please check highlighted field(s).")}
          </div>
        )}

        <div className="form-field">
          <RenderFields
            type={this.props.formType + "/" + this.props.definition.identifier}
            language={this.props.language}
            mode="edit"
            data={this.props.data}
            beforeField={this.props.beforeField}
            afterField={this.afterField}
            afterLabel={(fieldDef, data) => (
              <>
                {fieldDef.info && fieldDef.info.csrd && (
                  <>
                    <ReactTooltip
                      id={fieldDef.identifier + "-csrd"}
                      effect="solid"
                      place="right"
                      html={true}
                      clickable={true}
                      multiline={true}
                      delayHide={500}
                      className="tip"
                    >
                      {fieldDef.info.csrd}
                    </ReactTooltip>
                    <i
                      className="icon-csrd"
                      data-for={fieldDef.identifier + "-csrd"}
                      data-tip=""
                    />
                  </>
                )}
              </>
            )}
            validation={this.state.validation}
          />
        </div>

        <div className="step-action">
          {this.props.step != 0 && (
            <button
              type="button"
              name="_submit[next_]"
              className="btn btn-sm btn-primary"
              onClick={(event) => this.handlePreviousButton(event)}
            >
              <i className="fas fa-arrow-left"></i> {i18n.t("Previous step")}
            </button>
          )}
          {this.props.step <= this.props.totalSteps - 2 && (
            <button
              type="button"
              name="_submit[next]"
              className="btn btn-sm btn-primary"
              onClick={(event) => this.handlNextButton(event)}
            >
              <i className="fas fa-arrow-right"></i> {i18n.t("Next step")}
            </button>
          )}
          {this.props.step == this.props.totalSteps - 1 && (
            <button
              type="button"
              name="_submit[save]"
              className="btn btn-sm btn-primary btn-send"
              onClick={(event) => this.submit(event)}
            >
              <i className="fas fa-paper-plane"></i> {i18n.t("Send")}
            </button>
          )}
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => this.saveDraft()}
          >
            <i className="fas fa-save"></i> {i18n.t("Save draft")}
          </button>
        </div>
      </div>
    );
  }
}
