import * as React from "react";
import { useState } from "react";

import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

export const ExpandableLine = (props: {
  title: string;
  className?: string;
  body: any;
}) => {
  const [shown, setShown] = useState(false);

  const click = () => {
    setShown(!shown);
  };

  return (
    <div className={"line dmb-expandable " + (shown ? "open" : "")}>
      <div className="line-header" onClick={click}>
        {props.title}

        <span className="right icon-open">
          <i className="fa fa-plus"></i>
        </span>
      </div>
      <SlideDown>
        {shown && <div className="line-body">{props.body()}</div>}
      </SlideDown>
    </div>
  );
};
