/*
 * This is to initialize all.
 * This includes:
 * - field types.
 */

import FieldRegister from "digimaker-ui/FieldRegister";
import Preview from "eth-common/fieldtype/Preview";
import { registerFields } from "eth-common/registerFields";
import SupplychainEmployeeAverage from "./SupplychainEmployeeAverage";

FieldRegister.registerComponent(
  "report/supplychain_employee_average",
  SupplychainEmployeeAverage
);
FieldRegister.registerComponent("preview", Preview);

registerFields();
