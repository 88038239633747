//render content based on settings/blocks
import * as React from "react";
import { BlockArticle } from "./block/Article";
import { BlockPartner } from "./block/Partner";
import { LineArticle } from "./line/Article";
import { LinePartner } from "./line/Partner";
import { LineBlock } from "./line/Block";
import Resource from "./module/Resource";
import Level2Articles from "./module/Level2Articles";
import "./RenderContent.css";
import { FullArticle } from "./full/Article";
import { FullFolder } from "./full/Folder";
import Blocks from "./module/Blocks";
import ExpandArticles from "./module/ExpandArticles";

//register componets
const list = {
  article: {
    block: (content, params) => <BlockArticle content={content} {...params} />,
    full: (content, params) => <FullArticle content={content} {...params} />,
    line: (content, params) => <LineArticle content={content} {...params} />,
  },
  folder: {
    module: (content, params) => {
      let modules = {
        resource: <Resource content={content} />,
        "kom-i-gang": <Level2Articles content={content} />,
        blocks: <Blocks content={content} />,
        "articles-expand": <ExpandArticles content={content} />,
      };
      if (modules[params.module.value]) {
        return modules[params.module.value];
      }
      return <div>No module found</div>;
    },
    full: (content, params) => <FullFolder content={content} {...params} />,
  },
  partner: {
    block: (content, params) => <BlockPartner content={content} {...params} />,
    line: (content, params) => <LinePartner content={content} {...params} />,
  },
  block: {
    line: (content, params) => <LineBlock content={content} {...params} />,
  },
};

export const RenderContent = (props: {
  mode: string;
  content: any;
  params?: any;
}) => {
  let contenttype = props.content.metadata.contenttype;
  if (list[contenttype] && list[contenttype][props.mode]) {
    return list[contenttype][props.mode](props.content, props.params);
  } else {
    return (
      <div>
        No component found on {contenttype}/{props.mode}
      </div>
    );
  }
};
