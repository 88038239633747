import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Dashboard from "./home/Dashboard";
import ViewContent from "./content/ViewContent";
import BenchmarkContainer from "./home/BenchmarkContainer";
import Messages from "./home/Messages";
import MyReport from "./home/MyReport";
import { Reporting } from "./home/Reporting";
import View from "./home/View";
import ViewVersion from "./home/ViewVersion";
import Logout from "./user/Logout";
import History from './home/History';
import ViewProfile from "./user/ViewProfile";
import { Settings } from "./home/Settings";

export const Routes = ()=>{
    return <Switch>
          <Redirect from="/" to="/dashboard" exact />
          <Route path="/dashboard" exact component={Dashboard} /> 
          <Route path="/myreport" exact component={MyReport} />                                  
          <Route path="/myreport/settings" exact component={Settings} />     
          <Route path="/reporting" exact component={Reporting} />                    
          <Route path="/myreport/history" component={History} />
          <Route path="/messages" component={Messages} />
          <Route path="/myreport/view/:id" component={View} />
          <Route path="/version/:id/:version" component={ViewVersion} />
          <Route path="/profile" component={ViewProfile} />
          <Route path="/logout" component={Logout} />
          <Route path="/myreport/benchmark" component={BenchmarkContainer} />
          <Route path="/:path" component={ViewContent} />    
        </Switch>   
       ;
}