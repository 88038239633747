import * as React from "react";
import { ExpandableLine } from "../shared/Common";

export const LinePartner = (props: { content: any }) => {
  return (
    <ExpandableLine
      className="col-sm partner-line"
      title={props.content.metadata.name}
      body={() => (
        <div>
          <div>{props.content.description}</div>
          <div>
            <label className="font-weight-bold">E-post:</label>{" "}
            <a href={"mailto:" + props.content.email}>{props.content.email}</a>
          </div>
          <div>
            <label className="font-weight-bold">Nettsted: </label>{" "}
            <a target="_blank" href={props.content.website}>
              {props.content.website}
            </a>
          </div>
        </div>
      )}
    />
  );
};
