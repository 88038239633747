import * as React from 'react';
import { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import i18n from 'eth-common/i18n/i18n';

export const Reporting = () =>{    

    return <Nav className="justify-content-center" defaultActiveKey="/myrepoart">       
            <Nav.Item>
            <Nav.Link eventKey="/myreport">{i18n.t("My report")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="/history">{i18n.t("Previous years")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="/benchmark">{i18n.t("Benchmark")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="/messages">{i18n.t("Messages")}</Nav.Link>
            </Nav.Item>
        </Nav>;
}