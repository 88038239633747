import * as React from "react";
import { RouteProps } from "react-router";
import Moment from "react-moment";
import { EthContext } from "eth-common/EthContext";
import i18n from "eth-common/i18n/i18n";
import fetch from "isomorphic-fetch";
import { Link } from "react-router-dom";
export default class ResetPasswordConfirm extends React.Component<
  RouteProps,
  {
    value: string;
    valid: boolean;
    initError: any;
    success: boolean;
    error: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: "",
      valid: false,
      initError: "",
      success: false,
      error: "",
    };
  }

  componentDidMount() {
    let props: any = this.props;
    let hash = props.match.params.hash;
    this.checkHash(hash);
  }

  input(e: any) {
    this.setState({ value: e.target.value });
  }

  input2(e: any) {
    let value2 = e.target.value;
    this.setState({ valid: value2 == this.state.value });
  }

  checkHash(hash: string) {
    fetch(
      process.env.REACT_APP_REMOTE_URL + "/user/resetpassword-confirm/" + hash,
      { method: "POST" }
    ).then((res) => {
      if (!res.ok) {
        res.json().then((data) => {
          this.setState({ initError: data.data.message });
        });
      }
    });
  }

  submit() {
    if (!this.state.valid) {
      this.setState({ error: i18n.t("Password does not match.") });
      return;
    }
    let props: any = this.props;
    let hash = props.match.params.hash;
    let dataObject = { password: this.state.value };
    fetch(
      process.env.REACT_APP_REMOTE_URL + "/user/resetpassword-confirm/" + hash,
      { method: "POST", body: JSON.stringify(dataObject) }
    ).then((res) => {
      if (res.ok) {
        this.setState({ success: true, error: "" });
      } else {
        res.json().then((data) => {
          this.setState({ error: data.data.message });
        });
      }
    });
  }

  render() {
    return (
      <div className="main-anonymous">
        <h1>{i18n.t("Reset password")}</h1>
        {this.state.initError && (
          <div className="alert alert-warning">{this.state.initError}</div>
        )}
        {!this.state.initError && (
          <div>
            <div className="block">
              <div>
                <label>
                  {i18n.t("Password")}:{" "}
                  <input
                    type="password"
                    onChange={(e) => this.input(e)}
                    className="form-control"
                  />
                </label>
              </div>
              <div>
                <label>
                  {i18n.t("Repeat")}:{" "}
                  <input
                    type="password"
                    onChange={(e) => this.input2(e)}
                    className="form-control"
                  />
                </label>
              </div>
            </div>
            <div className="block">
              {this.state.success && (
                <div className="alert alert-info">
                  {i18n.t("You password is updated.")}
                </div>
              )}
              {this.state.error && (
                <div className="alert alert-warning">{this.state.error}</div>
              )}
              <input
                type="button"
                className="btn btn-primary btn-sm"
                onClick={(e) => this.submit()}
                value={i18n.t("Submit") as string}
              />
              &nbsp;&nbsp;<Link to="/">{i18n.t("Member page")}</Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ResetPasswordConfirm.contextType = EthContext;
