import * as React from "react";
import { RouteProps } from "react-router";
import Moment from "react-moment";
import { HashRouter as Router, Route, NavLink, Link } from "react-router-dom";
import { EthContext } from "eth-common/EthContext";
import i18n from "eth-common/i18n/i18n";
import { FetchWithAuth } from "digimaker-ui/util";
import FileUpload from "digimaker-ui/FileUpload";
import { Button } from "react-bootstrap";

export default class UploadLogo extends React.Component<
  {},
  { logoPath: string; newPath: string; mode: string }
> {
  constructor(props: any) {
    super(props);
    this.state = { logoPath: "", newPath: "", mode: "view" };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let data = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL + "/eth/user/current"
    );
    if (data.error === false) {
      this.setState({ logoPath: data.data.member.logo });
    }
  }

  uploaded(file) {
    this.setState({ newPath: file.nameUploaded });
  }

  async submit() {
    let data = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL + "/eth/user/upload-logo",
      {
        method: "POST",
        body: JSON.stringify({ logo: this.state.newPath }),
      }
    );
    if (data.data === true) {
      this.setState({ mode: "view" });
    }
  }

  render() {
    let path = this.state.logoPath;
    let currentPath = this.state.newPath ? this.state.newPath : path;
    return (
      <div>
        <div>
          {currentPath && (
            <img
              className="member-logo"
              src={process.env.REACT_APP_ASSET_URL + "/" + currentPath}
            />
          )}
        </div>

        {this.state.mode == "view" && (
          <Button size="sm" onClick={() => this.setState({ mode: "edit" })}>
            Upload logo
          </Button>
        )}
        {this.state.mode == "edit" && (
          <div>
            <h2>Upload new logo</h2>
            <FileUpload
              name="logo"
              format="image/*"
              service="content"
              onSuccess={(file) => this.uploaded(file)}
              value=""
            />
            <div>
              <br />
              <Button size="sm" onClick={() => this.submit()}>
                Submit
              </Button>{" "}
              &nbsp;&nbsp;
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ mode: "view" });
                }}
              >
                Cancel
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
