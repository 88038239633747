import * as React from "react";
import { useEffect, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { RenderContent } from "../RenderContent";

export const LineBlock = (props: { content: any }) => {
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.content.children_mode && props.content.children_type) {
      setLoading(true);
      let sortby =
        props.content.children_type == "article" ? "&sortby=priority desc" : "";
      let data = FetchWithAuth(
        process.env.REACT_APP_REMOTE_URL +
          "/content/list/" +
          props.content.children_type +
          "?parent=" +
          props.content.location.id +
          sortby
      ).then((data) => {
        if (data.error === false) {
          setChildren(data.data.list);
          setLoading(false);
        }
      });
    }
  }, children);

  return (
    <div className={props.content.children_mode ? "" : "block-container"}>
      {props.content.show_title == 1 && (
        <h2 className="block-name">{props.content.metadata.name}</h2>
      )}
      <div
        className="font-weight-bold text-size-2"
        dangerouslySetInnerHTML={{ __html: props.content.summary }}
      ></div>
      {loading && <span className="loading" />}

      {children && (
        <div className={"container"}>
          <div className="row">
            {children.map((child, index) => {
              if (props.content.first_fullwidth && index == 0) {
                return (
                  <div className="col-12">
                    <RenderContent
                      content={child}
                      mode={props.content.children_mode}
                      params={{ full: true }}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    className={"col-" + 12 / parseInt(props.content.columns)}
                  >
                    <RenderContent
                      content={child}
                      mode={props.content.children_mode}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};
