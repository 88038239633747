import * as React from 'react';
import { HashRouter as Router, Route, NavLink, Link } from "react-router-dom";
import {FetchWithAuth} from 'digimaker-ui/util';
import { EthContext } from 'eth-common/EthContext';
import Benchmark from 'eth-common/Benchmark';
import i18n from 'eth-common/i18n/i18n';
import { Button } from 'react-bootstrap';

export default class Dashboard extends React.Component<{}, {user:any, myBenchmarkYear:any, links:any, level:any, dashboardContent:any}> {

    constructor(props){
        super(props);
        this.state = {myBenchmarkYear:'',links:[], user:'', level:'', dashboardContent:''};
    }


  async componentDidMount(){
      let lastFinalYear = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL+'/eth/report/final-report-year');
      let myBenchmarkYear = null;
      if(lastFinalYear && lastFinalYear.error === false && lastFinalYear.data){
        myBenchmarkYear = lastFinalYear.data;
      }
      this.setState({myBenchmarkYear:myBenchmarkYear});

      if( myBenchmarkYear ){
        this.fetchLevel(myBenchmarkYear);
      }
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/user/current')
        .then(data=>{
            if( data.error === false ){
                this.setState({user: data.data});
            }
        });

       FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/list/article?parent='+process.env.REACT_APP_DASHBOARD_ID+'&sortby=priority desc')
       .then(data=>{
            if( data.data && data.data.list && data.data.list.length >0 ){
                this.setState({dashboardContent: data.data.list});
            }
       });
  }

  async fetchLinks(){
    let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/list/link');
    if( data.error === false ){
        this.setState({links: data.data.list});
    }
  }

  async fetchLevel(year){
    let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/level?id=self&year='+year);
    if( data.error === false ){
        this.setState({level: data.data});
    }
  }  

  render(){
    if( !this.state.user.member ){
        return <div className="loading"></div>
    }
    let language = this.context.language;
    if(!language){
      language = 'nor-NO';
    }

    let canReport = this.state.user&&this.state.user.member&&(this.state.user.member.can_report?true:false);
    let memberType = this.state.user.member.membership_type;

    return <div>
            <div className="content-wrapper">   
                    {canReport&&<><div className="block">
                        <p>
                        <br />
                            <span className="font-weight-bold">{i18n.t('Reporting to Ethical Trade Norway for company members')}</span> &nbsp;&nbsp;&nbsp;                            
                            <Link className="btn btn-primary" to="/myreport">{i18n.t('Report here')}</Link>
                            <br />
                            <br />
                        </p>                        
                    </div>

                    <hr />
                    <div>
                        <div className="right">
                            <Link to="myreport/benchmark"><i className="fas fa-angle-right"></i> {i18n.t('More')}</Link>
                        </div>
                        <h3>Benchmark{this.state.myBenchmarkYear?('('+this.state.myBenchmarkYear+')'):''}</h3>
                        {this.state.myBenchmarkYear&&<div><Benchmark self={true} year={this.state.myBenchmarkYear} dashboard={true} language={language}/>

                        <br /><br />                        
                     
                        {this.state.level&&<div><b>{i18n.t('Level result')}: </b>{this.state.level['_passed']?i18n.t('Passed'):i18n.t('Not passed')}</div>}   
                        </div>}

                        {this.state.myBenchmarkYear === null&&<div className='alert alert-info'>
                            {i18n.t('No final report found')}
                        </div>}

                    </div>
                    </>}

                    {!canReport&&this.state.dashboardContent&&<div className="block" dangerouslySetInnerHTML={{__html: (memberType=='virksomhet_offentlig'?this.state.dashboardContent[0].summary:this.state.dashboardContent[0].summary)}}></div>}
                
            </div>
        </div>
  }
}

Dashboard.contextType = EthContext;