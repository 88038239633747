import * as React from "react";
import Step from "./Step";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { EthContext } from "eth-common/EthContext";
import Moment from "react-moment";
import i18n from "eth-common/i18n/i18n";
import fetch from "isomorphic-fetch";
import DMInit from "digimaker-ui/DMInit";
import { FetchWithAuth } from "digimaker-ui/util";
import "./form.css";

import { Util } from "../util/Util";
import { buildObject } from "eth-common/buildObject";

export default class Form extends React.Component<
  { id: number; formType: string; beforeField: any },
  {
    sending: boolean;
    language: string;
    definition: any;
    currentStep: number;
    maxStep: number;
    data: any;
    draftTime: "";
    success: boolean;
    error: string;
    savedResult: any;
  }
> {
  private form: any;

  constructor(props: any) {
    super(props);
    this.state = {
      sending: false,
      language: "",
      definition: "",
      currentStep: 0,
      draftTime: "",
      maxStep: 0,
      data: {},
      success: false,
      error: "",
      savedResult: "",
    };
    this.form = React.createRef();
  }

  //when init
  async componentDidMount() {
    this.setState({ language: this.context.language });
    let canProceed = await this.fetchExistingData();
    if (canProceed) {
      this.fetchDefinition();
      this.autoSave(60);
    }
  }

  componentDidUpdate() {
    //todo: use different way of language instead of state to judge if the language is changed.
    //    variables in class?
    if (this.context.language != this.state.language) {
      this.setState({ language: this.context.language });
      this.fetchDefinition();
    }
  }

  fetchDefinition() {
    let language = this.context.language;
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/contenttype/get/" +
        this.props.formType +
        "?language=" +
        language
    )
      .then((data) => {
        this.setState({ definition: data.data });
      })
      .catch((err) => {
        console.error("err: " + err);
      });
  }

  //fetch existing data and return can proceed
  async fetchExistingData() {
    let data = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/eth/report/draft?session_id=" +
        Util.getFormSessionID()
    );
    if (data.error) {
      window.alert(data.data.message);
      return false;
    } else {
      //has draft
      if (data.data) {
        let formdata = JSON.parse(data.data.data);
        this.setState({ data: formdata, draftTime: data.created });
        return true;
      } else {
        //no draft
        let id = this.props.id;
        if (id) {
          let data = await FetchWithAuth(
            process.env.REACT_APP_REMOTE_URL + "/eth/report/current"
          );
          if (data.data) {
            this.setState({ data: data.data });
          } else {
            console.log("No content found.");
            return false;
          }
        }
        return true;
      }
    }
  }

  //todo: change to update if content is not empty.

  //Submit to server
  submit() {
    let data = this.state.data;
    console.log(data);
    let url = "/eth/report/send";
    if (this.props.id > 0) {
      url += "?id=" + this.props.id;
    }
    this.setState({ sending: true });
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + url, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((data) => {
      if (data.error) {
        this.setState({
          sending: false,
          error: i18n.t("Something is wrong. Detail") + data.data.message,
        });
      } else if (data.error === false) {
        Util.formSessionID = "";
        this.setState({ sending: false, success: true, error: "" });
      }
    });
  }

  //save draft to server
  handlerAction = (type: string, data: any): any => {
    if (type == "submit") {
      this.submit();
    } else if (type == "saveDraft") {
      this.saveDraft();
    }
  };

  //Save current step data to state
  saveStepData(stepData: any) {
    var stateData = this.state.data;
    stepData = this.mergeIndicator(stepData);
    stateData = { ...stateData, ...stepData };
    delete stateData["indicator_from"];
    this.setState({ data: stateData });
    return stateData;
  }

  // merge indicator list
  mergeIndicator(dataObject: any) {
    let fromFields = dataObject["indicator_from"];
    let existingList = this.state.data["indicator"];
    // if(existingList=='[]'){
    //   existingList = [];
    // }
    let currentData = dataObject["indicator"];

    //merge currentData
    let merged: Array<any> = [];
    if (currentData) {
      Object.keys(currentData).map((fromField: any) => {
        currentData[fromField].map((values: any) => {
          merged.push(values);
        });
      });
    }

    if (!existingList) {
      existingList = merged;
    } else {
      if (fromFields) {
        fromFields.map((from: any) => {
          //delete the whole from rows
          for (let i = existingList.length - 1; i >= 0; i--) {
            if (existingList[i].fromfield == from) {
              existingList.splice(i, 1);
            }
          }
        });
        if (currentData) {
          existingList = existingList.concat(merged); //add&update
        }
      }
    }

    dataObject["indicator"] = existingList;
    return dataObject;
  }

  //save and jump to step.
  jumpTo(toStep: number) {
    this.saveDraft();
    this.setState({
      currentStep: toStep,
      maxStep: toStep > this.state.maxStep ? toStep : this.state.maxStep,
    });
  }

  //set auto save
  autoSave(interval: number) {
    setInterval(this.saveDraft.bind(this), interval * 1000);
  }

  saveDraft() {
    if (!this.form.current || this.state.success) {
      return;
    }

    //save current step to global memory
    let data = this.getCurrentStepData();
    const formData = this.saveStepData(data);

    //send to server.
    let url = "/eth/report/savedraft?session_id=" + Util.getFormSessionID();
    let fData = { data: JSON.stringify(formData) };
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + url, {
      method: "POST",
      body: JSON.stringify(fData),
    })
      .then((data: any) => {
        if (data.error === false) {
          this.setState({ savedResult: { time: data.data, success: true } });
        } else {
          throw Error(i18n.t("Server error:" + data.data.message));
        }
      })
      .catch((ex: any) => {
        let savedResult = { success: false, message: ex.message };
        this.setState({ savedResult: savedResult });
      });
  }

  getCurrentStepData() {
    return buildObject(this.form.current);
  }

  getTotalStep() {
    let count = 0;
    for (let field of this.state.definition.fields) {
      if (field.type == "container") {
        count++;
      }
    }
    return count;
  }

  render() {
    const formDef = this.state.definition;
    return (
      formDef && (
        <div>
          <DMInit
            viewSettings={(c) => {
              return {} as any;
            }}
          >
            <form
              ref={this.form}
              className={this.state.sending ? "sending" : ""}
            >
              {this.state.draftTime && (
                <div className="right alert-info">
                  {i18n.t("Form draft on")}{" "}
                  <Moment unix format="DD.MM.YYYY HH:mm:ss">
                    {this.state.draftTime}
                  </Moment>
                </div>
              )}
              {this.state.savedResult && (
                <div className="savedraft-result">
                  <span className="saved-time alert-info">
                    {!this.state.savedResult.success && (
                      <span className="error">
                        {i18n.t("Current saving failed.")}{" "}
                        {this.state.savedResult.message}{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = window.location.href;
                          }}
                        >
                          {i18n.t("Refresh page")}
                        </a>
                      </span>
                    )}
                    {this.state.savedResult.success && (
                      <span>
                        {i18n.t("Saved on")}{" "}
                        <Moment unix format="DD.MM.YYYY HH:mm:ss">
                          {this.state.savedResult.time}
                        </Moment>
                      </span>
                    )}
                  </span>
                </div>
              )}
              <h2>
                {this.state.language === "nor-NO" &&
                this.props.formType === "report"
                  ? "Rapport"
                  : formDef.name}
              </h2>
              {!this.state.success && (
                <Tabs
                  selectedIndex={this.state.currentStep}
                  onSelect={(
                    index: number,
                    lastIndex: number,
                    event: Event
                  ) => {
                    this.jumpTo(index);
                  }}
                >
                  <TabList>
                    {formDef.fields.map((step: any, index: number) => {
                      return <Tab>{step.name}</Tab>;
                    })}
                  </TabList>

                  {formDef.fields.map((stepDef: any, index: number) => {
                    return (
                      <TabPanel>
                        <Step
                          definition={stepDef}
                          formType={this.props.formType}
                          language={this.state.language}
                          data={this.state.data && this.state.data}
                          form={this.form.current}
                          step={index}
                          totalSteps={this.getTotalStep()} //one internal field
                          onJumpTo={(step: number) => {
                            this.jumpTo(step);
                          }}
                          beforeField={this.props.beforeField}
                          onSubmit={(data: any) => {
                            this.saveStepData(data);
                            this.submit();
                          }}
                          handleAction={(type: string, data: any) => {
                            return this.handlerAction(type, data);
                          }}
                        />
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
              {this.state.sending && (
                <span className="loading">
                  <img alt="" width={30} src="/loading.gif" />
                </span>
              )}
              {this.state.success && (
                <div className="alert alert-success">
                  {i18n.t("The report is sent for approval.")}
                </div>
              )}
              {this.state.error && (
                <div className="alert alert-error">{this.state.error}</div>
              )}
            </form>
          </DMInit>
        </div>
      )
    );
  }
}

Form.contextType = EthContext;
