import * as React from "react";
import { Link } from "react-router-dom";
import { Util } from "../../util/Util";

export const BlockArticle = (props: { content: any; full?: boolean }) => {
  if (props.full) {
    return (
      <div className="article-block ">
        <div>
          <img
            className="width-full"
            src={
              process.env.REACT_APP_ASSET_URL + "/" + props.content.coverimage
            }
          />
          <div className="position-bottom space-small">
            <h1 className="color-white text-shadow-small">
              {props.content.metadata.name}
            </h1>
            <Link className="btn-round" to={Util.getContentUrl(props.content)}>
              Les mer
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="article-block">
      <Link to={Util.getContentUrl(props.content)}>
        <h2>{props.content.metadata.name}</h2>
        <div dangerouslySetInnerHTML={{ __html: props.content.summary }}></div>
        <div>
          <img
            style={{ width: "24px" }}
            src="https://etiskhandel.no/wp-content/themes/etiskhandel/img/icon-article-arrow2.svg"
          />
        </div>
      </Link>
    </div>
  );
};
