import * as React from 'react';


export default class SupplychainEmployeeAverage extends React.Component<{definition:any, validation:any, mode:string, data:any},{data:Array<{primary:string, file:string, name:string}>}> {
    constructor(props) {
        super(props);
        this.initEvents()
    }

    initEvents(){
        let employee = window.document.getElementById('supplychain_employee_number');
        console.log('employee number');
        console.log(employee);
        if( employee != null ){
            // let employee2:HTMLElement = employee;
            // employee2.onchange(e=>{
            //     console.log('ff');
            // });
        }
    }

    render(){      
        let def = this.props.definition;
        // <div className={
        //     "field " + def.type + " " + def.identifier}>            
        //     <label>{def.name}</label>
        //     <input width={100} disabled={true} type="form-control" />
        // </div>

        return <span></span>;
    }
}