import * as React from 'react';
import { RouteProps } from 'react-router';
import MessageList from '../user/MessageList';


export default class Messages extends React.Component<RouteProps, {}> {

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return  <MessageList />
  }
}
