import React from "react";
import "react-tabs/style/react-tabs.css";
import "./App.css";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  NavLink,
  Link,
  Redirect,
} from "react-router-dom";
import Login from "./user/Login";
import ResetPassword from "./user/ResetPassword";
import ResetPasswordConfirm from "./user/ResetPasswordConfirm";
import Header from "./Header";
import "./Init";
import ErrorBoundary from "./ErrorBoundary";
import { EthContext } from "eth-common/EthContext";
import i18n from "eth-common/i18n/i18n";
import { Side } from "./Side";
import { Routes } from "./Routes";
import Report from "./report/Report";
export default class App extends React.Component<{}, { contextValue: any }> {
  constructor(props) {
    super(props);
    this.state = { contextValue: { language: "nor-NO", currentYear: 0 } };
  }

  updateContext(key, value) {
    let ctx = this.state.contextValue;
    ctx[key] = value;
    this.setState({ contextValue: ctx });
  }

  render() {
    return (
      <EthContext.Provider value={this.state.contextValue}>
        <ErrorBoundary>
          <Router>
            <div className="App">
              <Switch>
                <Route path="/login" component={Login} />
                <Route
                  path="/resetpassword-confirm/:hash"
                  component={ResetPasswordConfirm}
                />
                <Route path="/resetpassword" exact component={ResetPassword} />
                <Route>
                  <Header
                    updateContext={(key, value) =>
                      this.updateContext(key, value)
                    }
                  />
                  {this.state.contextValue.currentYear == 0 && (
                    <div className="loading"></div>
                  )}
                  {this.state.contextValue.currentYear > 0 && (
                    <div className="main">
                      <div className="main-layout">
                        <Switch>
                          <Route path="/report/:id" component={Report} />
                          <Route>
                            <div className="main-area">
                              <Routes />
                            </div>
                            <Side />
                          </Route>
                        </Switch>
                      </div>
                    </div>
                  )}
                </Route>
              </Switch>
            </div>
          </Router>
        </ErrorBoundary>
      </EthContext.Provider>
    );
  }
}
