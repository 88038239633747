//render content based on settings/blocks
import * as React from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { FetchWithAuth } from "digimaker-ui/util";
import Select from "react-select";
import "./Level2Articles.css";
import { useEffect, useState } from "react";
import { ExpandableLine } from "../shared/Common";
import { RenderContent } from "../RenderContent";

export default class ExpandArticles extends React.Component<
  { content: any },
  { children: any; subFolder: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { children: [], subFolder: [] };
  }

  componentDidMount() {
    this.fetchChildren();
    this.fetchSubFolder();
  }

  async fetchChildren() {
    let id = this.props.content.location.id;
    let data = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/content/list/article?parent=" +
        id +
        "&level=1&limit=0&sortby=priority desc"
    );
    if (data.error === false) {
      this.setState({ children: data.data.list });
    }
  }

  async fetchSubFolder() {
    let id = this.props.content.location.id;
    let data = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/content/list/folder?parent=" +
        id +
        "&level=1&limit=0"
    );
    if (data.error === false) {
      this.setState({ subFolder: data.data.list });
    }
  }

  render() {
    let folder = this.props.content;
    return (
      <div>
        {/* <h2>{folder.name}</h2> */}
        <div className="content-container">
          <div dangerouslySetInnerHTML={{ __html: folder.summary }} />
          <div className="line-container">
            {this.state.children.map((article) => (
              <ExpandableLine
                title={article.metadata.name}
                body={() => (
                  <div
                    dangerouslySetInnerHTML={{ __html: article.body }}
                    className="content"
                  ></div>
                )}
              />
            ))}
          </div>
        </div>
        {this.state.subFolder.map((subFolder) => (
          <div>
            <ExpandArticles key={subFolder.id} content={subFolder} />
          </div>
        ))}
      </div>
    );
  }
}
