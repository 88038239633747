import * as React from "react";
import { RouteProps } from "react-router";
import Moment from "react-moment";
import { EthContext } from "eth-common/EthContext";
import i18n from "eth-common/i18n/i18n";
import fetch from "isomorphic-fetch";
import { Link } from "react-router-dom";
export default class ResetPassword extends React.Component<
  {},
  { value: string; success: boolean; error: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { value: "", success: false, error: "" };
  }

  componentDidMount() {
    // this.fetchLevels();
  }

  input(e: any) {
    this.setState({ value: e.target.value });
  }

  submit() {
    fetch(
      process.env.REACT_APP_REMOTE_URL +
        "/user/resetpassword/" +
        this.state.value +
        "?language=" +
        this.context.language
    ).then((res) => {
      if (res.ok) {
        this.setState({ success: true, error: "" });
      } else {
        res.json().then((data) => {
          this.setState({ error: data.data.message });
        });
      }
    });
  }

  render() {
    return (
      <div className="main-anonymous">
        <h1>{i18n.t("Reset password")}</h1>
        <div className="block">
          <label>
            {i18n.t("Email address")}:{" "}
            <input
              type="text"
              onChange={(e) => this.input(e)}
              className="form-control"
            />
          </label>
        </div>
        <div className="block">
          {this.state.success && (
            <div className="alert alert-info">
              {i18n.t(
                "An email was sent to you email address. Please follow the instruction to reset password."
              )}
            </div>
          )}
          {this.state.error && (
            <div className="alert alert-warning">{this.state.error}</div>
          )}
          <input
            type="button"
            className="btn btn-primary btn-sm"
            onClick={(e) => this.submit()}
            value={i18n.t("Submit") as string}
          />
          &nbsp;&nbsp;
          <Link to="/login">{i18n.t("Back")}</Link>
        </div>
      </div>
    );
  }
}

ResetPassword.contextType = EthContext;
