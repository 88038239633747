import * as React from 'react';
import { useEffect, useState } from 'react';
import {FetchWithAuth} from 'digimaker-ui/util';
import i18n from 'eth-common/i18n/i18n';

export const Side = () =>{
    const [links, setLinks] = useState([] as any);
    const [message, setMessage] = useState('' as any);
    const [profile, setProfile] = useState({} as any);

    useEffect(()=>{
        FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/list/folder?field.module=sideblock')
        .then(data=>{
            if( data.error === false ){
                if( data.data.count ){
                    setMessage( data.data.list[0]);
                    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/list/link?location_id='+data.data.list[0].id)
                    .then(data=>{
                        if( data.error === false ){
                            setLinks( data.data.list);
                        }
                    }); 
                } 
            }
        });      
        
    }, [])

    useEffect(()=>{
        FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/user/current')
        .then(data=>{
            if( data.error === false ){
                setProfile( data.data);
            }
        });       
    }, profile)

    return <div className="side noprint">
    {(message&&message.summary)&&<div className="db-block" dangerouslySetInnerHTML={{__html: message.summary}}></div>}
    {links.length>0&&<div className="db-block">
        <div>
            {links.map((link)=>
                <div>                    
                    <a className="link-high" href={link.url} target="_blank">
                        {link.title}
                        <i className="fas fa-external-link-alt"></i>
                        </a>
                </div>
            )}
        </div>
    </div>}

    <div className="db-block">
            <h2>Min profil</h2>
            {profile.member&&<div>
                <table>
                    <tr>
                        <td> {i18n.t('Name')}:</td>
                        <td>{profile.member.name}</td>                                            
                    </tr>
                    <tr>
                        <td> {i18n.t('Membership type')}:</td>
                        <td>{profile.member.membership_type?profile.member.membership_type.text:''}</td>                                            
                    </tr>
                    <tr>
                        <td> {i18n.t('Membership since')}:</td>
                        <td>
                            {profile.member.registration_date}    
                        </td>                                            
                    </tr>
                    <tr>
                        <td> {i18n.t('Branch')}:</td>
                        <td>
                        {profile.member.branch?profile.member.branch.text:''}
                        </td>                                            
                    </tr>
                    <tr>
                        <td> {i18n.t('Category')}:</td>
                        <td>
                         {profile.member.category?profile.member.category.text:''}
                        </td>                                            
                    </tr>                    
                    <tr>
                        <td> {i18n.t('Users having access')}:</td>     
                        <td>{profile.all_users&&<div>{profile.all_users.map(item=><div>{item}</div>)}</div>}</td>
                    </tr>
                    <tr>
                        <td> {i18n.t('Main contact')}:</td>     
                    </tr>
                </table>

                {(profile.member&&profile.member.main_person?true:false)&&<Person person={profile.member.main_person} />}

            </div>}
        </div>

        <div className="db-block">
            <h2>{i18n.t('Your contact person')}</h2>
            {(profile.member&&profile.member.contact_person?true:false)&&<Person person={profile.member.contact_person} />}
        </div>
    </div>
}


const Person = (props:{person:any})=>{
    return <div className="container">
    <div className="row">
    <div className="col-4">
    <div className="profile-picture">
        {props.person.picture&&
            <img src={process.env.REACT_APP_ASSET_URL+'/'+props.person.picture} />
        }
        {!props.person.picture&&
            <i className="fas fa-user" style={{fontSize: '60px', color: '#999999'}}></i>
        }
        </div>
    </div>
    <div className="col-8">
        <div>
            {props.person.name} <br />
            {props.person.position}
        </div>
        <div className="block">
        <div>
            <a href={"mailto:"+props.person.email}>
                {props.person.email}
            </a>
        </div>
        <div>
            <a href={"tel:+47"+props.person.phone}>
                {props.person.phone}
            </a>
        </div>
        </div>
    </div>
    </div>
</div>
}