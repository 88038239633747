import * as React from 'react';
import Benchmark from 'eth-common/Benchmark';
import { EthContext } from 'eth-common/EthContext';
import { Link } from 'react-router-dom';
import i18n from 'eth-common/i18n/i18n';

export default class BenchmarkContainer extends React.Component<{}, {}> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }


    //when init
    componentDidMount() {
        // this.fetchContent();
    }


    render() {
       let language = this.context.language;
       if(!language){
         language = 'nor-NO';
       }
        return (
          <div className="content-wrapper">
                <div className="right">
                        <Link to="/myreport"><i className="fas fa-arrow-circle-left"></i> {i18n.t("Back")}</Link>
                </div>
              <Benchmark self={true} language={language}/>
          </div>
        )
    }
}

BenchmarkContainer.contextType = EthContext;
