import * as React from "react";
import { RouteProps } from "react-router";
import Moment from "react-moment";
import { EthContext } from "eth-common/EthContext";
import EthIndicator from "eth-common/EthIndicator";
import ViewReport from "eth-common/ViewReport";

import fetch from "isomorphic-fetch";
import { FetchWithAuth } from "digimaker-ui/util";
import i18n from "eth-common/i18n/i18n";

export default class View extends React.Component<
  RouteProps,
  { content: any; evaluationOnly: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { content: "", evaluationOnly: false };
  }

  fetchData(id: any) {
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/content/get/" + id).then(
      (data) => {
        this.setState({ content: data.data });
      }
    );
  }

  //when init
  componentDidMount() {
    let props: any = this.props;
    let id = props.match.params.id;
    this.fetchData(id);
  }

  render() {
    if (!this.state.content) {
      return <div className="loading"></div>;
    }

    let language = this.context.language;
    return (
      <div
        className={this.state.evaluationOnly ? "evaluation-only" : ""}
        style={{ fontFamily: "Arial", fontSize: "0.9rem" }}
      >
        <a className="noprint right" href="javascript:window.print();">
          <i className="fas fa-print"></i> {i18n.t("Print")}
        </a>
        <label className="noprint right">
          <input
            type="checkbox"
            checked={this.state.evaluationOnly}
            value={"1"}
            onChange={() =>
              this.setState({ evaluationOnly: !this.state.evaluationOnly })
            }
          />
          {i18n.t("Evaluations only")} &nbsp;
        </label>
        <h2>
          {i18n.t("View")} {this.state.content.metadata.name}
        </h2>
        <ViewReport content={this.state.content} language={language} />
      </div>
    );
  }
}

View.contextType = EthContext;
